import React from "react";

import { Button as AntButton, ButtonProps as AntButtonProps } from "antd";

interface ButtonProps extends AntButtonProps {
  status?: "success" | "danger" | "info" | "default";
}

export const Button: React.FC<ButtonProps> = ({
  status = "default",
  ...rest
}) => {
  const buttonStatus = {
    success: "bg-status-success text-white",
    danger: "bg-status-danger text-white",
    info: "bg-status-info text-white",
    default: "",
  };

  return (
    <AntButton
      {...rest}
      className={`${rest.className} ${buttonStatus[status]}`}
    >
      {rest.children}
    </AntButton>
  );
};
