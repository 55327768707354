import { Form as AntForm, Flex, Input } from "antd";
import React from "react";
import { Button } from "../components/Button";
import { useAuth } from "../hooks/useAuth";
import { LogoLogin } from "../assets/icon/LogoLogin";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

export const Login: React.FC = () => {
  const {
    loading,
    actions: { handleSignIn },
  } = useAuth();

  return (
    <section className="w-full flex items-center justify-center mb-32">
      <AntForm onFinish={handleSignIn} layout="vertical">
        <Flex vertical className="items-center justify-center">
          <LogoLogin width={250} height={250} />
          <Flex vertical gap={"small"}>
            <AntForm.Item label="E-mail" name={"email"}>
              <Input placeholder="Email" required />
            </AntForm.Item>

            <AntForm.Item label="Senha" name={"password"}>
              <Input.Password
                placeholder="Senha"
                type="password"
                required
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </AntForm.Item>

            <AntForm.Item noStyle>
              <Button htmlType="submit" status="info" loading={loading}>
                Entrar
              </Button>
            </AntForm.Item>
          </Flex>
        </Flex>
      </AntForm>
    </section>
  );
};
