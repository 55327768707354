import * as React from "react";
import { SVGProps } from "react";
export const Logo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="3.894 4.395 402.032 117.986"
    {...props}
  >
    <defs>
      <clipPath id="a" clipPathUnits="userSpaceOnUse">
        <path d="M0 385.043h1177.043V0H0Z" />
      </clipPath>
    </defs>
    <g
      clipPath="url(#a)"
      transform="matrix(.36731 0 0 -.36731 -10.404 133.054)"
    >
      <path
        d="M0 0c-5.801 2.469-6.986 12.738-2.646 22.937C1.695 33.135 9.916 39.402 15.717 36.933c5.802-2.469 6.986-12.738 2.646-22.937C14.023 3.797 5.801-2.469 0 0m-17.198 32.739c-1.159-13.322-8.242-23.587-15.82-22.928-7.578.66-12.781 11.994-11.622 25.316 1.159 13.322 8.242 23.587 15.82 22.928 7.578-.659 12.781-11.994 11.622-25.316M-68.39 4.723c-8.417-1.696-17.656 8.926-20.636 23.722-2.98 14.797 1.428 28.166 9.845 29.861 8.417 1.696 17.655-8.926 20.635-23.722 2.98-14.797-1.427-28.166-9.844-29.861m-26.182-16.225c-5.604-3.648-15.345 1.381-21.757 11.231-6.412 9.851-7.068 20.794-1.465 24.441 5.603 3.648 15.344-1.381 21.756-11.232 6.413-9.85 7.069-20.793 1.466-24.44m34.798 125.722c-56.868 0-106.017-48.729-108.417-105.547-1.166-27.597 5.053-60.635 53.639-123.214 40.627-52.329 65.366-92.453 65.366-92.453 1.005 37.106 2.984 60.441-9.006 79.237-13.655 21.405-68.394 77.321-29.302 104.939 9.26 6.542 22.402 5.668 29.162 1.133 2.96-1.986 11.242-8.219 11.242-8.219s5.445 6.824 7.672 9.409c8.069 9.369 19.632 14.07 31.658 10.414 12.147-3.693 21.963-14.861 22.4-30.603.248-8.92.921-19.056-14.868-40.697-3.097-4.245-11.997-13.649-16.261-16.373l4.414.145C24.077-70.004 43.063-21.202 43.063 12.082c0 56.869-45.969 102.138-102.837 102.138"
        style={{
          fill: "#D76622",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(207.244 236.05)"
      />
      <path
        d="M0 0c-1.955-.357-3.442-.54-4.417-.54-5.466 0-10.407 2.325-14.686 6.908-4.397 4.699-7.845 10.851-10.254 18.289-2.45 7.55-4.337 14.79-5.603 21.516a186.851 186.851 0 0 0-2.671 20.042l-.002 12.219c.498 7.427 2.217 13.843 5.108 19.07 2.8 5.057 7.045 7.516 12.984 7.516 6.03 0 11.654-4.417 16.713-13.128 5.212-8.991 9.567-21.01 12.937-35.702C17.101 22.244 13.677 3.335 0 0m-12.364 121.046c-.351 0-27.395-.187-81.265-.558-2.951 0-4.664-1.491-4.724-4.098l-.228-.993c-.194-.829-.287-1.785-.287-2.923 0-1.214.207-2.165.633-2.907.376-.866 1.86-2.988 8.759-10.87 5.163-5.899 7.783-10.903 7.783-14.874V-34.408c0-3.785-2.662-8.741-7.912-14.733-8.379-9.567-8.565-10.187-8.643-10.487-.793-1.737-.827-3.966-.105-6.805.043-1.2.549-2.277 1.507-3.201.982-.954 2.064-1.437 3.217-1.437h67.423c1.32 0 2.533.53 3.608 1.582 1.082 1.066 1.631 2.243 1.631 3.497v2.514c0 1.377-.98 4.075-8.623 12.797-5.334 6.084-8.041 11.371-8.041 15.715v21.836c7.712-2.652 16.204-3.996 25.267-3.996 20.731 0 37.65 6.653 50.286 19.772C50.559 15.764 56.967 32.355 56.967 51.96c0 19.236-6.581 35.736-19.561 49.039-12.982 13.303-29.725 20.047-49.77 20.047"
        style={{
          fill: "#EEF3F3",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(374.74 137.346)"
      />
      <path
        d="M0 0c-7.187 5.98-16.139 9.013-26.604 9.013-.538 0-1.306-.048-2.297-.147-1.02-.106-1.582-.085-1.951-.145-10.902-1.437-19.501-6.603-25.591-15.37-2.817 9.393-6.405 13.967-10.966 13.967h-.003c-4.599-.195-12.337-2.295-23.662-6.419-11.416-4.16-18.37-7.925-21.169-11.407-1.767-1.735-2.662-3.922-2.662-6.505 0-2.652.938-4.682 2.615-5.885.427-.472 1.431-1.279 5.446-3.356a17.475 17.475 0 0 0 6.621-5.961c1.653-2.516 2.493-6.141 2.493-10.773l.002-47.501c.153-2.926-.858-6.054-3.006-9.294a61.466 61.466 0 0 0-7.674-9.421c-3.897-3.914-4.831-5.72-5.133-6.734-.933-1.741-1.126-4.059-.595-6.675 0-1.809.68-4.848 5.241-4.848h66.91c3.212 0 4.981 1.822 4.981 5.131v2.54c0 1.386-.949 4.107-8.354 12.914-5.172 6.15-7.796 11.495-7.796 15.888v42.887c.333 14.082 2.326 25.327 5.921 33.42 3.497 7.882 8.176 11.673 14.475 11.456 3.504 0 5.859-1.271 7.198-3.889 1.393-2.734 1.456-5.842.263-9.337-2.644-5.049-3.984-9.511-3.984-13.267 0-4.685 1.782-8.555 5.292-11.502 3.433-2.891 8.332-4.358 14.56-4.358 5.588 0 10.387 2.065 14.27 6.139 3.875 4.068 5.839 9.333 5.839 15.63C10.854-14.06 7.259-6.049 0 0"
        style={{
          fill: "#EEF3F3",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(558.181 193.736)"
      />
      <path
        d="M0 0c-1.733-11.069-6.112-17.368-13.389-19.256l-.097-.031c-.889-.318-2.313-.48-4.231-.48-6.714 0-12.724 4.401-17.863 13.082-5.295 8.936-9.345 20.651-12.047 34.849-3.199 14.508-3.799 27.525-1.784 38.694 1.955 10.836 6.59 17.279 13.782 19.152 1.271.336 2.293.507 3.042.507 6.208 0 11.96-4.357 17.099-12.949 5.292-8.845 9.689-20.62 13.073-34.998C.951 24.222 1.764 11.245 0 0m24.873 82.189c-12.982 12.889-29.728 19.423-49.768 19.423-19.868 0-36.569-6.534-49.638-19.421-13.066-12.895-19.691-28.952-19.691-47.724 0-19.137 6.449-35.373 19.172-48.262 12.722-12.893 29.596-19.43 50.157-19.43 20.729 0 37.648 6.539 50.29 19.434C38.028-.904 44.434 15.333 44.434 34.467c0 18.774-6.581 34.831-19.561 47.722"
        style={{
          fill: "#EEF3F3",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(667.73 99.5)"
      />
      <path
        d="M0 0c-.77 14.096 8.712 19.523 17.157 19.604.051.001.1.008.15.008 4.416 0 8.606-2.018 11.423-4.988l4.442-4.683 6.831 7.845c3.612 3.655 8.656 5.891 14.2 5.891 4.343 0 9.44-1.95 13.644-5.693C79.776 7.361 73.22-10.049 71.75-13.502 60.303-40.393 21.486-47.097 4.386-47.423 4.386-47.423.322-5.896 0 0m25.883-156.035c-13.015 0-20.013 74.904-20.013 103.741 23.573 0 89.902 10.029 89.902 47.027 0 24.155-27.768 40.436-55.351 40.436-15.369 0-32.061-4.556-75.662-4.556-.684 0-.684-1.016 0-1.016 13.014 0 16.437-57.578 16.437-90.541 0-32.964-3.423-95.098-16.437-95.098-.684 0-.684-1.016 0-1.016l61.124.007c.685 0 .685 1.016 0 1.016"
        style={{
          fill: "#EEF3F3",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(756.494 223.333)"
      />
      <path
        d="M0 0c0 20.574 11.173 33.641 23.9 42.866 6.974 5.054 18.01 10.633 20.736 19.586.018-1.812.018-96.571.018-96.571C23.358-42.169 0-33.791 0 0m27.182 91.521c-8.892 0-31.897-2.699-40.068-4.097V68.596C-7.665 76.559 4.05 87.15 19.836 87.15c16.111 0 22.496-7.145 22.496-15.163 0-12.054-11.844-17.118-20.005-20.355-23.822-9.448-50.408-19.393-50.408-53.065 0-29.273 20.018-43.63 44.711-43.63 11.402 0 26.113 7.995 42.388 7.995 4.55 0 12.688-1.23 12.688-3.444 0-.61 1.029-.61 1.029 0v90.443c0 30.435-18.294 41.59-45.553 41.59"
        style={{
          fill: "#EEF3F3",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(869.249 111.338)"
      />
      <path
        d="M0 0c-14.628-3.588-34.516 6.06-34.516 32.455l-.004 102.997.004-.003h23.243v4.911H-34.52v45.23s-43.73-12.699-44.514-12.905c-.664-.177-.66-1.214 0-1.026 18.843 5.389 16.439-24.301 16.439-139.199 0-28.692 13.382-40.948 34.223-40.948 16.125 0 25.03 5.161 28.656 7.89C.575-.374.358.091 0 0"
        style={{
          fill: "#EEF3F3",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(1029.527 74.763)"
      />
      <path
        d="M0 0c0 20.574 11.173 33.641 23.9 42.866 6.974 5.054 18.01 10.633 20.736 19.586.018-1.812.018-96.571.018-96.571C23.358-42.169 0-33.791 0 0m27.182 91.521c-8.892 0-31.897-2.699-40.068-4.097V68.596C-7.665 76.559 4.05 87.15 19.836 87.15c16.111 0 22.496-7.145 22.496-15.163 0-12.054-11.844-17.118-20.005-20.355-23.822-9.448-50.408-19.393-50.408-53.065 0-29.273 20.018-43.63 44.711-43.63 11.402 0 26.113 7.995 42.388 7.995 4.55 0 12.688-1.23 12.688-3.444 0-.61 1.029-.61 1.029 0v90.443c0 30.435-18.294 41.59-45.553 41.59"
        style={{
          fill: "#EEF3F3",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(1060.719 111.338)"
      />
    </g>
  </svg>
);
