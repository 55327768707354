import { toast } from "react-toastify";
import { api } from ".";
import { ITicket, ITicketItem } from "../../domain/entities/Ticket";

export async function getAllTickets(): Promise<ITicket[] | undefined> {
  try {
    return await api.get(`api/v1/ticket/`);
  } catch (error) {
    toast.error("Erro ao buscar o ticket");
  }
}

export async function getTicket(id: string): Promise<ITicket | undefined> {
  try {
    return await api.get(`api/v1/ticket/${id}`);
  } catch (error) {
    toast.error("Erro ao buscar o ticket");
  }

}

export async function patchStatusTicket(id: string, data: string) {
  try {
    await api.patch(`api/v1/ticket/status/${id}`, {
      status: data,
    });
    toast.success("Status atualizado com sucesso");
  } catch (error) {
    toast.error("Erro ao atualizar o status");
  }
}

export async function postInfoTickets(
  id: string,
  data: ITicketItem
) {
  try {
    await api.post(`api/v1/ticket/item/${id}`, data);
    toast.success("Informações Inseridas com sucesso");
  } catch (error) {
    toast.error("Erro ao inserir as Informações");
  }
}

export async function patchTicket(id: string, data: ITicket) {
  try {
    const { id, status, ...body } = data;
    await api.patch(`api/v1/ticket/${id}`, body);
    toast.success("Chamado atualizado com sucesso");
  } catch (error) {
    toast.error("Erro ao atualizar o Chamado");
  }
}

export async function postTicket(data: ITicket) {
  try {
    const { status, ...body } = data;
    await api.post(`api/v1/ticket/`, body);
    toast.success("Chamado criado com sucesso");
  } catch (error) {
    toast.error("Erro ao realizar o cadastro do Chamado");
  }
}

export async function patchRejectTicket(id: string, data: string) {
  try {
    await api.patch(`api/v1/ticket/status/${id}`, {
      reject_reason: data,
      status: "DENIED",
    });
    toast.success("Chamado atualizado com sucesso");
  } catch (error) {
    toast.error("Erro ao atualizar o Chamado");
  }
}
