import { toast } from "react-toastify";
import { api } from ".";
import { IPet, IPetFilters } from "../../domain/entities/Pet";

const buildQueryParams = (filters: IPetFilters): IPetFilters => {
  const filteredEntries = Object.entries(filters).filter(
    ([, value]) => value !== ""
  );
  return Object.fromEntries(filteredEntries) as IPetFilters;
};

export async function getAllPets(
  filters: IPetFilters
): Promise<IPet[] | undefined> {
  try {
    const params = buildQueryParams(filters);
    return await api.get(`api/v1/pet/`, {
      params,
    });
  } catch (error) {
    toast.error("Erro ao buscar pet");
  }
}

export async function patchPetMicrochip(id: string, data: string) {
  try {
    await api.patch(`api/v1/pet/${id}`, {
      microchip_number: data,
    });
    toast.success("Microchip Registrado com sucesso");
  } catch (error) {
    toast.error("Erro ao Registrar MicroChip");
  }
}

export async function getAllPetsByIdNumber(
  id: string
): Promise<IPet[] | undefined> {
  try {
    return await api.get(`/api/v1/pet/tutor-id-number/${id}`);
  } catch (error) {
    toast.error("Erro ao buscar pet");
  }
}
