import { ILicitation } from "./Licitation";
import { IService } from "./Service";
import { IServiceProvider } from "./ServiceProvider";

export interface IItem {
  id: string;
  order_id: string;
  doc_url: string;
  type: keyof typeof ItemType;
  manager_id: string;
  licitation?: ILicitation;
  items_services: IItemsServices[];
}

export enum ItemType {
  LICITATION = "LICITATION",
  "DIRECT_BUY" = "DIRECT_BUY",
}

export interface IItemsServices {
  id: string;
  service: IService;
  quantity: number;
  unit_price: number;
  service_provider: IServiceProvider;
  service_provider_id: string;
  status: string | null;
  allocation?: {
    allocated: number;
    used: number;
  };
}
