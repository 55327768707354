import { useCallback, useContext, useState } from "react";
import {
  getAllRequirements,
  getAllRequirementsServiceProvider,
  getConcludeRequirement,
  getRequirement,
  patchRejectRequirements,
  patchRequirement,
  patchStatusRequirements,
  postConcludeRequirementService,
  postNewRequirementService,
  postRequirement,
} from "../../data/service/requirement";
import {
  IRequirement,
  IRequirementService,
} from "../../domain/entities/Requirement";
import dayjs from "dayjs";
import { UserContext } from "../../domain/context/user/UserContext";
import { uploadFile } from "../../data/service/bucket";

export function useRequirement() {
  const userContext = useContext(UserContext);

  const [loading, setLoading] = useState(false);
  const [requirement, setRequirement] = useState<IRequirement | undefined>();
  const [requirementList, setRequirementList] = useState<
    IRequirement[] | undefined
  >();
  const [requirementServiceList, setRequirementServiceList] = useState<
    IRequirementService[] | undefined
  >();

  const handleGetRequirement = useCallback(async (id: string) => {
    setLoading(true);
    const selectedRequirement = await getRequirement(id);
    setRequirement(selectedRequirement);
    setLoading(false);
  }, []);

  const handleGetAllRequirements = useCallback(async () => {
    setLoading(true);
    if (
      userContext?.user?.scopes?.includes(
        "requirement:requirement_services:read"
      )
    ) {
      setRequirementServiceList(await getAllRequirementsServiceProvider());
    }

    if (userContext.user?.scopes?.includes("requirement:read")) {
      setRequirementList(await getAllRequirements());
    }
    setLoading(false);
  }, [userContext.user?.scopes]);

  const handlePatchUpdateStatus = useCallback(
    async (id: string, statusData: string) => {
      setLoading(true);
      await patchStatusRequirements(id, statusData);
      setLoading(false);
    },
    []
  );

  const handleRejectRequirement = useCallback(
    async (id: string, reason: string) => {
      setLoading(true);
      await patchRejectRequirements(id, reason);
      setLoading(false);
    },
    []
  );

  const handleSubmitRequirement = useCallback(async (data: any) => {
    setLoading(true);

    const {
      "veterinarian.id": veterinarian_id,
      requirement_services,
      new_requirement_services,
      ...body
    } = data;

    const forwarding =
      data.destination === "Encaminhamento" ? data.forwarding : null;

    if (data.id) {
      const services =
        new_requirement_services &&
        new_requirement_services.map((service: IRequirementService) => ({
          quantity: service.quantity,
          service_id: service.service.id,
        }));

      if (services && services.length > 0) {
        await postNewRequirementService(data.id, services);
      }

      await patchRequirement({
        ...body,
        date: dayjs().format("YYYY-MM-DD"),
        forwarding,
      });
    } else {
      const services =
        requirement_services &&
        requirement_services.map((service: IRequirementService) => ({
          quantity: service.quantity,
          service_id: service.service.id,
        }));
      await postRequirement({
        ...body,
        requirement_services: services,
        veterinarian_id: veterinarian_id || null,
        date: dayjs().format("YYYY-MM-DD"),
        forwarding,
      });
    }

    setLoading(false);
  }, []);

  const handleConfirmRequirementService = useCallback(
    async (serviceId: string, values: any) => {
      const body = values;

      body.invoice_url = await uploadFile(body.invoice_url.file);

      await postConcludeRequirementService(serviceId, body);
    },
    []
  );

  const handleConcludeRequirement = useCallback(async (id: string) => {
    setLoading(true);
    await getConcludeRequirement(id);
    setLoading(false);
  }, []);

  return {
    loading,
    requirement,
    requirementList,
    requirementServiceList,
    actions: {
      handleGetRequirement,
      handleGetAllRequirements,
      handlePatchUpdateStatus,
      handleSubmitRequirement,
      handleRejectRequirement,
      handleConfirmRequirementService,
      handleConcludeRequirement,
    },
  };
}
