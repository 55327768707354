import { ITutor } from "./Tutor";

export interface IPet {
  id?: string;
  name?: string;
  image_url?: string;
  species?: string;
  race?: string;
  gender?: string;
  microchip_number?: string | null;
  is_castrated?: boolean;
  type_of_home?: string;
  age?: string;
  weight?: string;
  tutor_id?: string;
  tutor?: ITutor;
  status?: string;
}

export enum PetGender {
  MACHO = "Macho",
  FÊMEA = "Fêmea",
}

export enum TypeOfHome {
  TEMPORARY = "Temporário",
  PERMANENT = "Permanente",
}

export const PetSpecies = [
  "Canino",
  "Felino",
  "Ave",
  "Reptil",
  "Bovino",
  "Suino",
  "Caprino",
  "Equino",
  "Outros",
];

export type PetSpeciesType =
  | "Canino"
  | "Felino"
  | "Ave"
  | "Reptil"
  | "Bovino"
  | "Suino"
  | "Caprino"
  | "Equino"
  | "Outros";

export const PetRace = ["Pitbull", "SRD", "Outro"];

export type PetRaceType = "Pitbull" | "SRD" | "Outro";

export interface IPetFilters {
  id?: string;
  name?: string;
  image_url?: string;
  species?: string;
  race?: string;
  gender?: string;
  microchip_number?: string | null;
  is_castrated?: string | boolean;
  type_of_home?: string;
  age?: string;
  weight?: string;
  tutor_id?: string;
  status?: string;
}
