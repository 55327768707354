import { toast } from "react-toastify";
import { api } from ".";
import {
  IRequirement,
  IRequirementService,
} from "../../domain/entities/Requirement";

export async function getRequirement(
  id: string
): Promise<IRequirement | undefined> {
  try {
    return await api.get(`api/v1/requirement/${id}`);
  } catch (error) {
    toast.error("Erro ao buscar a Denúncia");
  }
}

export async function getAllRequirements(): Promise<
  IRequirement[] | undefined
> {
  try {
    return await api.get(`api/v1/requirement/`);
  } catch (error) {
    toast.error("Erro ao buscar a Requerimento");
  }
}

export async function getAllRequirementsServiceProvider(): Promise<
  IRequirementService[] | undefined
> {
  try {
    return await api.get(`api/v1/requirement/requirement-services`);
  } catch (error) {
    toast.error("Erro ao buscar a Requerimento");
  }
}

export async function patchStatusRequirements(id: string, data: string) {
  try {
    await api.patch(`api/v1/requirement/update-status/${id}`, { status: data });
    toast.success("Status atualizado com sucesso");
  } catch (error) {
    toast.error("Erro ao atualizar o status");
  }
}

export async function patchRejectRequirements(id: string, reason: string) {
  try {
    await api.patch(`api/v1/requirement/update-status/${id}`, {
      rejected_reason: reason,
      status: "REJECTED",
    });
    toast.success("Requerimento indeferido");
  } catch (error) {
    toast.error("Erro ao indeferir o Requerimento");
  }
}

export async function postRequirement(data: IRequirement) {
  try {
    await api.post(`api/v1/requirement/`, data);
    toast.success("Requerimento cadastrado com sucesso");
  } catch (error) {
    toast.error("Error ao realizar o cadastro do Requerimento");
  }
}

export async function patchRequirement(data: IRequirement) {
  try {
    const { id, ...body } = data;

    await api.patch(`api/v1/requirement/update-info/${id}`, body);
    toast.success("Requerimento atualizado com sucesso");
  } catch (error) {
    toast.error("Erro ao atualizar o Requerimento");
  }
}

export async function postNewRequirementService(
  requirementId: string,
  data: { quantity: number; service_id: string }[]
) {
  try {
    await api.post(`api/v1/requirement/add-service/${requirementId}`, data);
  } catch (error) {
    toast.error("Erro ao inserir o novo serviço");
  }
}

export async function postConcludeRequirementService(
  serviceId: string,
  data: { quantity_consumed: number; invoice_url: string }
) {
  try {
    await api.post(
      `api/v1/requirement/requirement-services/conclude/${serviceId}`,
      data
    );
    toast.success("Serviço finalizado com sucesso");
  } catch (error) {
    toast.error("Erro ao finalizar o Serviço");
  }
}

export async function getConcludeRequirement(id: string) {
  try {
    await api.get(`api/v1/requirement/conclude/${id}`);
    toast.success("Requerimento concluido");
  } catch (error) {
    toast.error("Erro ao concluir o Serviço");
  }
}
