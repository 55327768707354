import { useCallback, useState } from "react";
import {
  getAllDenouncements,
  getDenouncement,
  postInfoDenouncements,
} from "../../data/service/denouncement";
import { IDenouncement } from "../../domain/entities/Denouncement";
import { uploadFile } from "../../data/service/bucket";

export function useDenouncement() {
  const [loading, setLoading] = useState(false);
  const [denouncement, setDenouncement] = useState<IDenouncement | undefined>();
  const [denouncementList, setDenouncementList] = useState<
    IDenouncement[] | undefined
  >();

  const handleGetDenouncement = useCallback(async (id: string) => {
    setLoading(true);
    const selectedDenouncement = await getDenouncement(id);
    setDenouncement(selectedDenouncement);
    setLoading(false);
  }, []);

  const handleGetAllDenouncements = useCallback(async () => {
    setLoading(true);
    const selectedAllDenouncement = await getAllDenouncements();
    setDenouncementList(selectedAllDenouncement);
    setLoading(false);
  }, []);

  const handlePostDenouncementInfo = async (id: string, data: any) => {
    let url = null;
    if (data.denouncement_file) {
      url = await uploadFile(data.denouncement_file);
    }
    setLoading(true);
    await postInfoDenouncements(id, { ...data, file_url: url });
    setLoading(false);
  };

  return {
    loading,
    denouncement,
    denouncementList,
    actions: {
      handleGetDenouncement,
      handleGetAllDenouncements,
      handlePostDenouncementInfo,
    },
  };
}
