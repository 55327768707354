import React, { useMemo } from "react";

import { Breadcrumb as AntBreadcrumb } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { ItemType } from "antd/es/breadcrumb/Breadcrumb";

const BREADCRUMBS_TITLE: { [key: string]: string } = {
  dashboard: "Painel de Controle",
  "service-provider": "Prestadores de Serviço",
  items: "Itens",
  stock: "Estoque",
  denouncement: "Denúncias",
  requirement: "Requerimentos",
  ticket: "Chamados",
  protector: "Protetores",
  campaign: "Campanha",
  add: "Novo",
  edit: "Editar",
  info: "Informação",
  actives: "Ativos",
  list: "Lista",
  animals: "Animais",
  queue: "Triagem",
  schedule: "Fila",
};

const BREADCRUMBS_BLOCKLIST: string[] = [
  "add",
  "edit",
  "info",
  "queue",
  "schedule",
];

export const Breadcrumb: React.FC = () => {
  const location = useLocation();

  const navigate = useNavigate();

  const breadcrumbItems = useMemo(
    () =>
      location.pathname
        .split("/")
        .filter((i) => i)
        .map((i, index, array): ItemType => {
          const isClickable =
            index !== array.length - 1 && !BREADCRUMBS_BLOCKLIST.includes(i);

          return {
            title: BREADCRUMBS_TITLE[i] || i,
            className: "cursor-pointer",
            onClick: () => {
              if (isClickable) {
                navigate(`/${array.slice(0, index + 1).join("/")}`);
              }
            },
          };
        }),
    [location, navigate]
  );

  return <AntBreadcrumb items={breadcrumbItems} />;
};
