import { useCallback, useState } from "react";
import {
  getAllPets,
  getAllPetsByIdNumber,
  patchPetMicrochip,
} from "../../data/service/pet";
import { IPet, IPetFilters } from "../../domain/entities/Pet";

export function usePet() {
  const [loading, setLoading] = useState(false);
  const [petList, setPetList] = useState<IPet[] | undefined>();

  const handleGetAllPets = useCallback(async (filters: IPetFilters) => {
    setLoading(true);
    const selectedAllPet = await getAllPets(filters);
    setPetList(selectedAllPet);
    setLoading(false);
  }, []);

  const handleGetAllPetsByIdNumber = useCallback(async (id: string) => {
    setLoading(true);
    const selectedAllPetByIdNumber = await getAllPetsByIdNumber(id);
    setPetList(selectedAllPetByIdNumber);
    setLoading(false);
  }, []);

  const handlePatchMicrochip = async (id: string, microchip_number: string) => {
    setLoading(true);
    await patchPetMicrochip(id, microchip_number);
    setLoading(false);
  };

  return {
    loading,
    petList,
    actions: {
      handleGetAllPets,
      handlePatchMicrochip,
      handleGetAllPetsByIdNumber,
    },
  };
}
