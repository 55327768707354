import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  Form as AntForm,
  Input,
  Row,
  Col,
  Flex,
  Card,
  Select,
  Typography,
  Spin,
  Table,
} from "antd";
import { Button } from "../../../components/Button";
import { useConfig } from "../../../hooks/useConfig";
import { FaTrash } from "react-icons/fa";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { flattenObj } from "../../../utils/object";
import { useRequirement } from "../../../hooks/useRequirement";
import { ROUTES } from "../../../Router";
import { useVeterinarian } from "../../../hooks/useVeterinarian";
import { UserContext } from "../../../../domain/context/user/UserContext";
import { useTicket } from "../../../hooks/useTicket";
import {
  IRequirementService,
  REQUIREMENT_DESTINATION,
} from "../../../../domain/entities/Requirement";
import { PetCard } from "../../../components/Cards/PetCard";
import { TutorCard } from "../../../components/Cards/TutorCard";
import { TableProps } from "antd/lib";

const { Text, Paragraph } = Typography;

export const Form: React.FC = () => {
  const [form] = AntForm.useForm();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [currentTicket, setCurrentTicket] = useState<string>();

  const { user } = useContext(UserContext);

  const {
    loading,
    requirement,
    actions: { handleGetRequirement, handleSubmitRequirement },
  } = useRequirement();
  const config = useConfig();

  const {
    ticketList,
    actions: { handleGetAllTickets },
  } = useTicket();

  const params = useParams();
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const handleTicketChange = useCallback(
    (value: string) => {
      form.setFieldsValue({ ticket_id: value });
      setCurrentTicket(value);
    },
    [form]
  );

  useEffect(() => {
    handleGetAllTickets();
  }, [handleGetAllTickets]);

  useEffect(() => {
    if (params.id) {
      handleGetRequirement(params.id);
      setIsEditing(true);
    }
  }, [handleGetRequirement, params.id]);

  useEffect(() => {
    const ticket_id = searchParams.get("ticket");

    if (ticket_id) {
      handleTicketChange(ticket_id);
    }
  }, [searchParams, handleTicketChange]);

  useEffect(() => {
    if (requirement) {
      const fields = flattenObj(requirement);

      form.setFieldsValue(fields);
    }
  }, [requirement, form]);

  const columns: TableProps<IRequirementService>["columns"] = useMemo(
    () => [
      {
        title: "Serviço",
        dataIndex: "service",
        key: "service",
        render: (_: any, record) => (
          <Paragraph ellipsis={{ rows: 2 }}>
            {record.service.service_name}
          </Paragraph>
        ),
      },
      {
        title: "Quantidade",
        dataIndex: "quantity",
        key: "quantity",
        responsive: ["md"],
        width: 130,
        ellipsis: {
          showTitle: false,
        },
        render: (_: any, record) => <Text>{record.quantity} unid.</Text>,
      },

      {
        title: "Custo Unitário",
        dataIndex: "cost",
        key: "cost",
        responsive: ["md"],
        width: 130,
        ellipsis: {
          showTitle: false,
        },
        render: (_: any, record) => (
          <Text>R$ {record.cost ? record.cost.toFixed(2) : "-"}</Text>
        ),
      },

      {
        title: "Custo Total",
        dataIndex: "total_cost",
        key: "total_cost",
        width: 120,
        ellipsis: {
          showTitle: false,
        },
        render: (_: any, record) => (
          <Text className="font-bold">
            R$ {(record.cost * record.quantity).toFixed(2)}
          </Text>
        ),
      },

      {
        title: "Disponibilidade",
        dataIndex: "available",
        key: "available",
        render: (_: any, record) => (
          <Text>{record.available ? "Disponível" : "Indisponível"}</Text>
        ),
      },
    ],
    []
  );

  const handleSubmit = useCallback(
    (values: any) => {
      try {
        handleSubmitRequirement(values);
        navigate(ROUTES.DASHBOARD.REQUIREMENT.ROOT);
      } catch (error) {
        console.error(error);
      }
    },
    [handleSubmitRequirement, navigate]
  );

  return (
    <AntForm
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      className="w-full"
    >
      <Spin spinning={loading}>
        <Flex vertical gap={"middle"}>
          <Card>
            <AntForm.Item name={"id"} noStyle hidden>
              <Input />
            </AntForm.Item>
            <Flex vertical gap="middle">
              <Card
                title={
                  <Flex justify="space-between">
                    <Text className="text-lg">Informações</Text>

                    <Flex gap={"small"}>
                      <Flex gap="small" align="center">
                        <Text>
                          Chamado
                          {isEditing &&
                            `: ${requirement?.ticket?.protocol_number}`}
                        </Text>
                        {!isEditing && (
                          <AntForm.Item
                            name={"ticket_id"}
                            rules={[
                              {
                                required: true,
                                message: "Chamado é obrigatório",
                              },
                            ]}
                            noStyle
                          >
                            <Select
                              size="middle"
                              data-testid="ticket-select"
                              className="!w-60"
                              value={currentTicket}
                              placeholder="Selecione um chamado"
                              options={
                                ticketList &&
                                ticketList.map((ticket) => ({
                                  label: ticket.protocol_number,
                                  value: ticket.id,
                                }))
                              }
                              disabled={!!currentTicket}
                            />
                          </AntForm.Item>
                        )}
                      </Flex>

                      {user?.scopes?.includes("veterinarian:read") &&
                        (isEditing ? (
                          <Flex align="center">
                            <Text>
                              Veterinário:{" "}
                              {requirement?.veterinarian?.user?.full_name}
                            </Text>
                          </Flex>
                        ) : (
                          <SelectVeterinarian />
                        ))}
                    </Flex>
                  </Flex>
                }
              >
                <Row gutter={{ xs: 8, md: 16 }}>
                  <Col xs={24} sm={24} md={12}>
                    <AntForm.Item
                      name={"description"}
                      label="Histórico"
                      rules={[
                        { required: true, message: "Histórico é obrigatório" },
                      ]}
                    >
                      <Input.TextArea
                        className="!h-32 !resize-none"
                        placeholder="Histórico"
                      />
                    </AntForm.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <AntForm.Item
                      initialValue={null}
                      name={"clinical_signs"}
                      label="Sinais Clinicos"
                    >
                      <Input.TextArea
                        className="!h-32 !resize-none"
                        placeholder="Sinais Clinicos"
                      />
                    </AntForm.Item>
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, md: 16 }}>
                  <Col xs={24} sm={24} md={12}>
                    <AntForm.Item
                      initialValue={null}
                      name={"differential_diagnosis"}
                      label="Diagnósticos Diferenciais"
                    >
                      <Input.TextArea
                        className="!h-32 !resize-none"
                        placeholder="Diagnósticos Diferenciais"
                      />
                    </AntForm.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <AntForm.Item
                      name={"destination"}
                      label="Destino"
                      rules={[
                        { required: true, message: "Destino é obrigatório" },
                      ]}
                    >
                      <Select
                        size="middle"
                        className="w-full"
                        placeholder="Selecione um Destino"
                        options={REQUIREMENT_DESTINATION.map((forwarging) => ({
                          label: forwarging,
                          value: forwarging,
                        }))}
                      />
                    </AntForm.Item>
                    <AntForm.Item
                      shouldUpdate={(prev, current) =>
                        prev.destination !== current.destination
                      }
                    >
                      {({ getFieldValue }) =>
                        getFieldValue("destination") === "Encaminhamento" && (
                          <AntForm.Item
                            name={"forwarding"}
                            label="Encaminhamento"
                          >
                            <Input />
                          </AntForm.Item>
                        )
                      }
                    </AntForm.Item>
                  </Col>
                </Row>
              </Card>
              <Row gutter={[16, 16]}>
                {requirement?.ticket?.pet ? (
                  <Col xs={24} sm={24} md={12}>
                    <PetCard {...requirement.ticket.pet} />
                  </Col>
                ) : (
                  <Col xs={24} sm={24} md={12}>
                    <PetCard
                      gender={requirement?.ticket?.gender}
                      species={requirement?.ticket?.species}
                    />
                  </Col>
                )}
                {requirement?.ticket?.pet?.tutor && (
                  <Col xs={24} sm={24} md={12}>
                    <TutorCard {...requirement.ticket.pet.tutor} />
                  </Col>
                )}
              </Row>
              {isEditing ? (
                <>
                  <Card title="Serviços atuais">
                    <Table
                      columns={columns}
                      dataSource={requirement?.requirement_services}
                    />
                  </Card>
                  <Card title="Novos serviços">
                    <AntForm.List name="new_requirement_services">
                      {(fields, { add, remove }) => (
                        <Flex vertical gap="middle" align="center">
                          {fields.map((field, index) => {
                            return (
                              <Card key={field.key} className="w-full">
                                <Row gutter={{ xs: 8, md: 16 }}>
                                  <Col xs={24} sm={24} md={12}>
                                    <AntForm.Item
                                      name={[field.name, "service", "id"]}
                                      label="Serviço"
                                      className="m-0"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Selecione um serviço",
                                        },
                                      ]}
                                    >
                                      <Select
                                        placeholder="Selecione"
                                        options={[
                                          ...config.services.map((service) => ({
                                            label: service.service_name,
                                            value: service.id,
                                          })),
                                        ]}
                                      />
                                    </AntForm.Item>
                                  </Col>
                                  <Col xs={24} sm={24} md={12}>
                                    <AntForm.Item
                                      name={[field.name, "quantity"]}
                                      label="Quantidade"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Quantidade é obrigatório",
                                        },
                                      ]}
                                    >
                                      <Input
                                        placeholder="Quantidade"
                                        type="number"
                                      />
                                    </AntForm.Item>
                                  </Col>
                                </Row>

                                {fields.length > 1 && (
                                  <Button
                                    danger
                                    data-testid="remove-item-service"
                                    className="absolute top-2 right-2"
                                    onClick={() => remove(index)}
                                  >
                                    <FaTrash />
                                  </Button>
                                )}
                              </Card>
                            );
                          })}

                          <Button
                            type="dashed"
                            data-testid="add-item-service"
                            onClick={() => add()}
                            className="!w-10 !h-10 rounded-full text-xl flex items-center justify-center"
                          >
                            +
                          </Button>
                        </Flex>
                      )}
                    </AntForm.List>
                  </Card>
                </>
              ) : (
                <Card title="Serviços">
                  <AntForm.List
                    name="requirement_services"
                    initialValue={form.getFieldValue("services") ?? [{}]}
                  >
                    {(fields, { add, remove }) => (
                      <Flex vertical gap="middle" align="center">
                        {fields.map((field, index) => {
                          return (
                            <Card key={field.key} className="w-full">
                              <Row gutter={{ xs: 8, md: 16 }}>
                                <Col xs={24} sm={24} md={12}>
                                  <AntForm.Item
                                    name={[field.name, "service", "id"]}
                                    label="Serviço"
                                    className="m-0"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Selecione um serviço",
                                      },
                                    ]}
                                  >
                                    <Select
                                      placeholder="Selecione"
                                      options={[
                                        ...config.services.map((service) => ({
                                          label: service.service_name,
                                          value: service.id,
                                        })),
                                      ]}
                                    />
                                  </AntForm.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12}>
                                  <AntForm.Item
                                    name={[field.name, "quantity"]}
                                    label="Quantidade"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Quantidade é obrigatório",
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder="Quantidade"
                                      type="number"
                                    />
                                  </AntForm.Item>
                                </Col>
                              </Row>

                              {fields.length > 1 && (
                                <Button
                                  danger
                                  data-testid="remove-item-service"
                                  className="absolute top-2 right-2"
                                  onClick={() => remove(index)}
                                >
                                  <FaTrash />
                                </Button>
                              )}
                            </Card>
                          );
                        })}

                        <Button
                          type="dashed"
                          data-testid="add-item-service"
                          onClick={() => add()}
                          className="!w-10 !h-10 rounded-full text-xl flex items-center justify-center"
                        >
                          +
                        </Button>
                      </Flex>
                    )}
                  </AntForm.List>
                </Card>
              )}
              <Flex justify="flex-end" gap={"small"}>
                <Button
                  htmlType="button"
                  status="info"
                  onClick={() => navigate(-1)}
                >
                  Cancelar
                </Button>
                <AntForm.Item noStyle>
                  <Button htmlType="submit" status="success">
                    Enviar
                  </Button>
                </AntForm.Item>
              </Flex>
            </Flex>
          </Card>
        </Flex>
      </Spin>
    </AntForm>
  );
};

const SelectVeterinarian: React.FC = () => {
  const {
    veterinarianList,
    actions: { handleGetAllVeterinarians },
  } = useVeterinarian();

  useEffect(() => {
    handleGetAllVeterinarians();
  }, [handleGetAllVeterinarians]);

  return (
    <Flex gap="small" align="center">
      <Text>Veterinário</Text>
      <AntForm.Item
        name={"veterinarian.id"}
        rules={[{ required: true, message: "Selecione um veterinário" }]}
        noStyle
      >
        <Select
          size="middle"
          className="!w-60"
          placeholder="Selecione um veterinário"
          options={veterinarianList.map((veterinarian) => ({
            label: veterinarian.user.full_name,
            value: veterinarian.id,
          }))}
        />
      </AntForm.Item>
    </Flex>
  );
};
