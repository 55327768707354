import { toast } from "react-toastify";
import { api } from ".";

export async function uploadFile(file: File): Promise<string> {
  try {
    const formData = new FormData();
    formData.append("file", file);

    return await api.post("/api/v1/bucket/uploadfile", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  } catch (e) {
    toast.error("Erro ao realizar o upload");
    return "";
  }
}
