import React, { useContext } from "react";
import { Outlet } from "react-router";
import { Navigate } from "react-router-dom";
import { UserContext } from "../../domain/context/user/UserContext";
import { ROUTES } from "../Router";

export const PublicRoute: React.FC = () => {
  const userContext = useContext(UserContext);

  if (userContext.user) {
    return <Navigate to={ROUTES.DASHBOARD.ROOT} />;
  }

  return <Outlet />;
};
