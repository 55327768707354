import { useCallback, useState } from "react";
import {
  deleteServiceProvider,
  getAllServiceProvider,
  getServiceProvider,
  patchServiceProvider,
  postServiceProvider,
} from "../../data/service/serviceProvider";
import { IServiceProvider } from "../../domain/entities/ServiceProvider";

export function useServiceProvider() {
  const [loading, setLoading] = useState<boolean>(false);
  const [serviceProvider, setServiceProvider] = useState<IServiceProvider>();
  const [serviceProviderList, setServiceProviderList] =
    useState<IServiceProvider[]>();

  const handleSubmitServiceProvider = useCallback(async (values: any) => {
    const body: any = { user: {} };

    setLoading(true);

    Object.entries(values).forEach(([key, value]) => {
      if (key.includes("user.")) {
        body.user[key.replace("user.", "")] = value;
        return;
      }

      body[key] = value;
    });

    if (values.id) {
      delete body.user.email;

      await patchServiceProvider(body);
    } else {
      await postServiceProvider(body);
    }

    setLoading(false);
  }, []);

  const handleGetServiceProvider = useCallback(async (id: string) => {
    setLoading(true);
    const selectedServiceProvider = await getServiceProvider(id);
    setServiceProvider(selectedServiceProvider);
    setLoading(false);
  }, []);

  const handleGetAllServiceProvider = useCallback(async () => {
    setLoading(true);
    const allServiceProvider = await getAllServiceProvider();
    setServiceProviderList(allServiceProvider);
    setLoading(false);
  }, []);

  const handleDeleteServiceProvider = useCallback(async (id: string) => {
    setLoading(true);
    await deleteServiceProvider(id);
    setLoading(false);
  }, []);

  return {
    loading,
    serviceProvider,
    serviceProviderList,
    actions: {
      handleSubmitServiceProvider,
      handleGetServiceProvider,
      handleGetAllServiceProvider,
      handleDeleteServiceProvider,
    },
  };
}
