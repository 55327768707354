import { useCallback, useState } from "react";
import {
  getAllTickets,
  getTicket,
  patchRejectTicket,
  patchStatusTicket,
  patchTicket,
  postInfoTickets,
  postTicket,
} from "../../data/service/ticket";
import { ITicket } from "../../domain/entities/Ticket";
import { UploadFile } from "antd";
import { uploadFile } from "../../data/service/bucket";

export function useTicket() {
  const [loading, setLoading] = useState(false);
  const [ticket, setTicket] = useState<ITicket | undefined>();
  const [ticketList, setTicketList] = useState<ITicket[] | undefined>();

  const handleGetAllTickets = useCallback(async () => {
    setLoading(true);
    const selectedAllTicket = await getAllTickets();
    setTicketList(selectedAllTicket);
    setLoading(false);
  }, []);

  const handleGetTicket = useCallback(async (id: string) => {
    setLoading(true);
    const selectedTicket = await getTicket(id);
    setTicket(selectedTicket);
  }, []);

  const handlePatchTicketStatus = async (id: string, statusData: string) => {
    setLoading(true);
    await patchStatusTicket(id, statusData);
    setLoading(false);
  };

  const handlePostTicketInfo = async (id: string, data: any) => {
    let url = null;
    if (data.file_url) {
      url = await uploadFile(data.file_url);
    }
    setLoading(true);
    await postInfoTickets(id, { ...data, file_url: url });
    setLoading(false);
  };

  const handlePatchRejectReason = async (id: string, rejectData: string) => {
    setLoading(true);
    await patchRejectTicket(id, rejectData);
    setLoading(false);
  };

  const handleSubmitTicket = useCallback(
    async (data: ITicket, fileList?: UploadFile[]) => {
      setLoading(true);
      const body = data;
      if (fileList) {
        const urls = await Promise.all(
          fileList
            .filter((file) => !file.url)
            .map((file) => uploadFile(file.originFileObj as File))
        );
        body.image_url = [...(body.image_url || []), ...urls];
      }
      if (data.id) {
        await patchTicket(data.id, body);
      } else {
        await postTicket(body);
      }
      setLoading(false);
    },
    []
  );

  return {
    loading,
    ticket,
    ticketList,
    actions: {
      handleGetAllTickets,
      handleGetTicket,
      handlePostTicketInfo,
      handlePatchTicketStatus,
      handleSubmitTicket,
      handlePatchRejectReason,
    },
  };
}
