import { toast } from "react-toastify";
import { api } from ".";
import {
  IDenouncement,
  IDenouncementItems,
} from "../../domain/entities/Denouncement";

export async function getDenouncement(
  id: string
): Promise<IDenouncement | undefined> {
  try {
    return await api.get(`api/v1/denouncement/${id}`);
  } catch (error) {
    toast.error("Erro ao buscar a Denúncia");
  }
}

export async function getAllDenouncements(): Promise<
  IDenouncement[] | undefined
> {
  try {
    return await api.get(`api/v1/denouncement/`);
  } catch (error) {
    toast.error("Erro ao buscar a Denúncia");
  }
}
export async function postInfoDenouncements(
  id: string,
  data: IDenouncementItems
) {
  try {
    await api.post(`api/v1/denouncement/item/${id}`, data);
    toast.success("Informações Inseridas com sucesso");
  } catch (error) {
    toast.error("Erro ao inserir as Informações");
  }
}
