import { toast } from "react-toastify";
import { api } from ".";
import {
  IProtector,
  IProtectorInterview,
} from "../../domain/entities/Protector";

export async function getAllProtectors(): Promise<
  IProtectorInterview[] | undefined
> {
  try {
    return await api.get(`api/v1/protector-interview/`);
  } catch (error) {
    toast.error("Erro ao buscar o protetor");
  }
}

export async function getProtector(
  id: string
): Promise<IProtectorInterview | undefined> {
  try {
    return await api.get(`api/v1/protector-interview/${id}`);
  } catch (error) {
    toast.error("Erro ao buscar o protetor");
  }
}

export async function getAllActiveProtectors(): Promise<
  IProtector[] | undefined
> {
  try {
    return await api.get(`api/v1/user-protector/`);
  } catch (error) {
    toast.error("Erro ao buscar o protetor");
  }
}

export async function getActiveProtector(
  id: string
): Promise<IProtector | undefined> {
  try {
    return await api.get(`api/v1/user-protector/${id}`);
  } catch (error) {
    toast.error("Erro ao buscar o protetor");
  }
}

export async function patchRejectProtector(id: string, data: string) {
  try {
    await api.patch(`api/v1/protector-interview/status/${id}`, {
      reject_reason: data,
      status: "DENIED",
    });
    toast.success("Protetor Rejeitado");
  } catch (error) {
    toast.error("Erro ao rejeitar");
  }
}

export async function patchAcceptProtector(id: string) {
  try {
    await api.patch(`api/v1/protector-interview/status/${id}`, {
      status: "APPROVED",
    });
    toast.success("Protetor Aceito com sucesso");
  } catch (error) {
    toast.error("Erro ao Aceitar o protetor");
  }
}

export async function deleteProtector(id: string) {
  try {
    await api.delete(`api/v1/user-protector/delete/${id}`);
    toast.success("Protetor excluído com sucesso");
  } catch (error) {
    toast.error("Erro ao deletar o protetor");
  }
}
