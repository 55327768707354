import { Flex, Spin, Typography } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "../components/Button";

import { FaCircleCheck } from "react-icons/fa6";
import { verifyAccount } from "../../data/service/tutor";
import { toast } from "react-toastify";
import { ROUTES } from "../Router";

import { LogoLogin } from "../assets/icon/LogoLogin";

const { Text } = Typography;

export const VerifyTutor: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [wasSuccess, setWasSuccess] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (!searchParams.get("token")) {
      toast.error("Usuário inválido");
      navigate(ROUTES.LOGIN);
    }
  }, [navigate, searchParams]);

  const handleVerifyAccount = useCallback(async () => {
    setLoading(true);
    try {
      const token = searchParams.get("token");
      if (token) {
        await verifyAccount(token);
        setWasSuccess(true);
      }
    } catch (error) {
      toast.error("Erro ao verificar sua conta");
    } finally {
      setLoading(false);
    }
  }, [searchParams]);

  return (
    <Flex className="w-full" align="center" justify="center">
      <Spin spinning={loading}>
        <div className="flex flex-col items-center gap-10  w-[350px]">
          <LogoLogin className="w-52" />
          {wasSuccess ? (
            <>
              <Text className="text-lg text-center font-bold">
                Conta verificada com sucesso!
              </Text>
              <FaCircleCheck className="text-6xl text-green-700" />
            </>
          ) : (
            <>
              <Text className="text-lg text-center font-bold">
                Bem-vindo ao Propata, clique no botão abaixo para confirmar seu
                email
              </Text>
              <Button
                type="primary"
                className="w-full"
                status="info"
                onClick={handleVerifyAccount}
              >
                Verificar e-mail
              </Button>
            </>
          )}
        </div>
      </Spin>
    </Flex>
  );
};
