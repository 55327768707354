import { IPet } from "./Pet";

export interface ITicket {
  denouncement_id: string;
  description: string;
  gender: string;
  id: string;
  image_url: string[];
  location_address: string;
  neighborhood: string;
  protocol_number: string;
  requester_user_id: string;
  species: string;
  status: string;
  pet?: IPet | null;
  veterinarian_id: string;
  ticket_items: ITicketItem[];
}

export interface ITicketItem {
  id: string;
  ticket_id: string;
  new_fact: string;
  file_url: string;
  date: string;
  status: string;
  created_at: string;
  updated_at: string;
}

export enum TicketStatus {
  OPEN = "Aberto",
  ON_GOING = "Em andamento",
  DENIED = "Negado",
  CLOSED = "Fechado",
}
