import { useContext } from "react";
import { UserContext } from "../../../../domain/context/user/UserContext";
import { Navigate, Outlet } from "react-router-dom";
import { ROUTES } from "../../../Router";

export * from "./List";

export const AnimalRoot: React.FC = () => {
  const userContext = useContext(UserContext);

  if (!userContext.user?.scopes?.includes("pet:read:all")) {
    return <Navigate to={ROUTES.DASHBOARD.ROOT} />;
  }

  return <Outlet />;
};
