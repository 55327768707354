import { useCallback, useState } from "react";
import { IDashboard } from "../../domain/entities/Dashboard";
import { getDashboard } from "../../data/service/dashboard";
import { toast } from "react-toastify";

export function useDashboard() {
  const [loading, setLoading] = useState<boolean>(false);
  const [dashboard, setDashboard] = useState<IDashboard>();

  const handleGetDashboard = useCallback(
    async (neighborhood?: string, months?: number) => {
      setLoading(true);
      try {
        const response = await getDashboard(neighborhood, months);
        setDashboard(response);
      } catch (error) {
        toast.error("Erro ao buscar o Dashboard");
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return { loading, dashboard, actions: { handleGetDashboard } };
}
