import { IItemsServices } from "./Item";
import { IService } from "./Service";
import { ITicket } from "./Ticket";
import { IVeterinarian } from "./Veterinarian";

export interface IRequirement {
  id: string;
  protocol_number: string;
  description: string;
  date: string;
  veterinarian?: IVeterinarian;
  veterinarian_id: string;
  clinical_signs: string;
  differential_diagnosis: string;
  destination: string;
  forwarding?: string | null;
  status: keyof typeof RequirementStatus;
  requirement_services: IRequirementService[];
  rejected_reason: string | null;
  ticket_id: string;
  ticket?: ITicket;
}

export interface IRequirementService {
  available?: boolean;
  id: string;
  invoice_url?: string | null;
  item_service: IItemsServices;
  item_service_id: string;
  quantity: number;
  quantity_consumed: number | null;
  cost: number;
  service: IService;
  status: keyof typeof RequirementSeriviceStatus;
}

export enum RequirementStatus {
  "OPEN" = "Aberto",
  "AWAITING_CONCLUSION" = "Aguardando Conclusão",
  "WAITING_APPROVE" = "Aguardando Aprovação",
  "REJECTED" = "Indeferido",
  "DISPATCHED" = "Dispachado",
  "CONCLUDED" = "Concluído",
  "SUSPENDED" = "Suspenso",
}

export enum RequirementSeriviceStatus {
  "OPEN" = "Aberto",
  "REJECTED" = "Rejeitado",
  "DISPATCHED" = "Dispachado",
  "SUSPENDED" = "Suspenso",
  "INVOICE_UPLOADED" = "Faturado",
  "UNEXECUTED" = "Não executado",
}

export const REQUIREMENT_DESTINATION = [
  "Domicílio com prescrição",
  "Domicílio sem prescrição",
  "Encaminhamento",
  "Clinica",
  "Protetor",
  "Animal está na rua",
];
