import { useCallback, useState } from "react";
import {
  IProtector,
  IProtectorInterview,
} from "../../domain/entities/Protector";
import {
  deleteProtector,
  getActiveProtector,
  getAllActiveProtectors,
  getAllProtectors,
  getProtector,
  patchAcceptProtector,
  patchRejectProtector,
} from "../../data/service/protector";

export function useProtector() {
  const [loading, setLoading] = useState(false);
  const [protector, setProtector] = useState<IProtectorInterview | undefined>();
  const [protectorList, setProtectorList] = useState<
    IProtectorInterview[] | undefined
  >();
  const [activeProtector, setActiveProtector] = useState<
    IProtector | undefined
  >();
  const [activeProtectorList, setActiveProtectorList] = useState<
    IProtector[] | undefined
  >();

  const handleGetAllProtectors = useCallback(async () => {
    setLoading(true);
    const selectedAllProtector = await getAllProtectors();
    setProtectorList(selectedAllProtector);
    setLoading(false);
  }, []);

  const handleGetProtectors = useCallback(async (id: string) => {
    setLoading(true);
    const selectedProtector = await getProtector(id);
    setProtector(selectedProtector);
    setLoading(false);
  }, []);

  const handleGetActiveProtectors = useCallback(async (id: string) => {
    setLoading(true);
    const selectedActiveProtector = await getActiveProtector(id);
    setActiveProtector(selectedActiveProtector);
    setLoading(false);
  }, []);

  const handleGetAllActiveProtectors = useCallback(async () => {
    setLoading(true);
    const selectedAllActiveProtector = await getAllActiveProtectors();
    setActiveProtectorList(selectedAllActiveProtector);
    setLoading(false);
  }, []);

  const handlePatchRejectReason = async (id: string, rejectData: string) => {
    setLoading(true);
    await patchRejectProtector(id, rejectData);
    setLoading(false);
  };

  const handlePatchAcceptReason = async (id: string) => {
    setLoading(true);
    await patchAcceptProtector(id);
    setLoading(false);
  };

  const handleDelete = useCallback(async (id: string) => {
    setLoading(true);
    await deleteProtector(id);
    setLoading(false);
  }, []);

  return {
    loading,
    protector,
    protectorList,
    activeProtector,
    activeProtectorList,
    actions: {
      handleGetAllProtectors,
      handleGetProtectors,
      handlePatchAcceptReason,
      handlePatchRejectReason,
      handleGetActiveProtectors,
      handleGetAllActiveProtectors,
      handleDelete,
    },
  };
}
