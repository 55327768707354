import {
  HTMLAttributes,
  createContext,
  useCallback,
  useEffect,
  useState,
} from "react";
import { IUser } from "../../entities/User";
import {
  revokeAccessToken,
  revokeRefreshToken,
} from "../../../data/service/auth";

type UserContextType = {
  user?: IUser;
  actions: {
    signIn: (data: IUser) => void;
    signOut: () => void;
  };
};

export const DEFAULT_USER_CONTEXT: UserContextType = {
  actions: {
    signIn: (data: IUser) => {},
    signOut: () => {},
  },
};

export const UserContext = createContext<UserContextType>(DEFAULT_USER_CONTEXT);

export const UserProvider: React.FC<HTMLAttributes<HTMLElement>> = ({
  children,
}) => {
  const storagedUser = localStorage.getItem("user");

  const [user, setUser] = useState<IUser | undefined>(
    storagedUser ? JSON.parse(storagedUser) : undefined
  );

  const handleSignIn = useCallback((data: IUser) => {
    localStorage.setItem("user", JSON.stringify(data));
    setUser(data);
  }, []);

  const handleSignOut = useCallback(() => {
    localStorage.removeItem("user");
    setUser(undefined);
    revokeAccessToken();
    revokeRefreshToken();
  }, []);

  useEffect(() => {
    if (storagedUser) {
      setUser(JSON.parse(storagedUser));
    } else {
      setUser(undefined);
    }
  }, [handleSignOut, storagedUser]);

  return (
    <UserContext.Provider
      value={{
        user,
        actions: {
          signIn: handleSignIn,
          signOut: handleSignOut,
        },
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
