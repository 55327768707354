import * as React from "react";
import { SVGProps } from "react";
export const LogoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="3.124 3.574 168.128 255.489"
    {...props}
  >
    <defs>
      <clipPath id="a" clipPathUnits="userSpaceOnUse">
        <path d="M0 1326.953h920.691V0H0Z" />
      </clipPath>
    </defs>
    <g clipPath="url(#a)" transform="matrix(.20486 0 0 -.20486 -7.77 266.759)">
      <path
        d="M0 0c-22.524 9.585-27.122 49.455-10.272 89.052 16.852 39.597 48.772 63.926 71.295 54.34 22.524-9.585 27.122-49.455 10.271-89.052C54.443 14.744 22.524-9.585 0 0m-66.771 127.111c-4.5-51.723-32-91.578-61.422-89.018-29.421 2.56-49.624 46.566-45.122 98.289 4.5 51.723 31.999 91.578 61.421 89.018 29.421-2.56 49.623-46.566 45.123-98.289M-265.525 18.336c-32.679-6.582-68.549 34.655-80.119 92.104-11.569 57.449 5.542 109.355 38.221 115.936 32.679 6.581 68.549-34.655 80.119-92.104 11.569-57.449-5.543-109.355-38.221-115.936m-101.654-62.994c-21.756-14.162-59.574 5.362-84.47 43.607-24.897 38.246-27.444 80.73-5.688 94.892 21.755 14.162 59.574-5.362 84.469-43.608 24.897-38.245 27.444-80.73 5.689-94.891m135.106 488.121c-220.792 0-411.612-189.194-420.933-409.79-4.528-107.145 19.618-235.418 208.255-478.382 157.737-203.165 253.787-358.949 253.787-358.949 3.9 144.064 11.586 234.662-34.969 307.64-53.015 83.104-265.539 300.201-113.764 407.428 35.951 25.399 86.978 22.003 113.222 4.397 11.49-7.708 43.645-31.911 43.645-31.911s21.143 26.496 29.787 36.532C-121.711-43.196-76.82-24.946-30.128-39.14c47.159-14.337 85.272-57.696 86.97-118.814.962-34.635 3.575-73.989-57.728-158.011-12.023-16.478-46.58-52.991-63.131-63.566l17.134.562C93.481-271.79 167.194-82.318 167.194 46.91c0 220.793-178.475 396.553-399.267 396.553"
        style={{
          fill: "#D76622",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="translate(706.67 841.223)"
      />
    </g>
  </svg>
);
