import {
  Card,
  Flex,
  Row,
  Select,
  Form as AntForm,
  Col,
  Input,
  Typography,
  Upload,
  UploadFile,
  UploadProps,
  GetProp,
  Image,
} from "antd";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../../Router";
import { Button } from "../../../components/Button";
import { useTicket } from "../../../hooks/useTicket";
import { useConfig } from "../../../hooks/useConfig";
import { PlusOutlined } from "@ant-design/icons";
import { UploadChangeParam } from "antd/es/upload";
import { useDenouncement } from "../../../hooks/useDenouncement";
import { flattenObj } from "../../../utils/object";
import { PetSpecies } from "../../../../domain/entities/Pet";
import { getBase64 } from "../../../utils/image";

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

export const TicketAdd: React.FC = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [currentDenouncement, setCurrentDenouncement] = useState<string>();

  const navigate = useNavigate();
  const [form] = AntForm.useForm();
  const { neighborhood } = useConfig();
  const { Text } = Typography;

  const { id } = useParams();

  const [searchParams] = useSearchParams();

  const [fileList, setFileList] = useState<UploadFile[]>();

  const {
    ticket,
    actions: { handleGetTicket, handleSubmitTicket },
  } = useTicket();

  const {
    denouncementList,
    actions: { handleGetAllDenouncements },
  } = useDenouncement();

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType);
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const handleChange = (info: UploadChangeParam<UploadFile>) => {
    setFileList(info.fileList);
  };

  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  const handleSubmit = useCallback(
    async (values: any) => {
      await handleSubmitTicket(values, fileList);

      navigate(ROUTES.DASHBOARD.TICKET.ROOT);
    },
    [handleSubmitTicket, navigate, fileList]
  );

  const handleDenouncementChange = useCallback(
    (value: string) => {
      form.setFieldsValue({ denouncement_id: value });
      setCurrentDenouncement(value);
    },
    [form]
  );

  useEffect(() => {
    if (id) {
      handleGetTicket(id);
      setIsEditing(true);
    }
    handleGetAllDenouncements();
  }, [id, handleGetTicket, handleGetAllDenouncements]);

  useEffect(() => {
    if (ticket) {
      const fields = flattenObj(ticket);

      form.setFieldsValue(fields);
      if (ticket.image_url) {
        setFileList(
          ticket.image_url.map((image, index) => ({
            url: image,
            uid: index.toString(),
            name: image,
          }))
        );
      }

      setCurrentDenouncement(fields.denouncement_id);
    } else {
      setFileList([]);
    }
  }, [ticket, form]);

  useEffect(() => {
    const denouncement_id = searchParams.get("denouncement_id");

    if (denouncement_id) {
      handleDenouncementChange(denouncement_id);
    }
  }, [searchParams, handleDenouncementChange]);

  return (
    <AntForm
      layout="vertical"
      onFinish={handleSubmit}
      form={form}
      className="w-full"
    >
      <AntForm.Item name={"id"} noStyle hidden>
        <Input />
      </AntForm.Item>
      <Flex vertical gap="middle">
        <Card
          title={
            <div className="flex items-center justify-between">
              <Flex>
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24}>
                    {isEditing ? (
                      <Text className="text-xl font-bold mr-2">
                        Solicitação:{" "}
                        <Text className="text-base font-medium">
                          {ticket?.protocol_number}
                        </Text>
                      </Text>
                    ) : (
                      <Text className="text-xl font-bold mr-2">
                        Nova Solicitação
                      </Text>
                    )}
                  </Col>
                </Row>
              </Flex>
            </div>
          }
        >
          <Row gutter={{ xs: 8, md: 16 }}>
            <Col xs={24} sm={24} lg={8}>
              <AntForm.Item
                label="Denúncia"
                initialValue={null}
                name="denouncement_id"
              >
                <Select
                  placeholder="Selecione uma Denúncia"
                  data-testid="denouncement-select"
                  value={currentDenouncement}
                  onChange={(value) => handleDenouncementChange(value)}
                  options={[
                    ...(denouncementList
                      ? denouncementList.map((denouncement) => ({
                          value: denouncement.id,
                          label: denouncement.protocol_number,
                        }))
                      : []),
                  ]}
                  disabled={!!currentDenouncement}
                />
              </AntForm.Item>
            </Col>
          </Row>

          <Row gutter={{ xs: 8, md: 16 }}>
            <Col xs={24} sm={24} md={24} lg={6}>
              <AntForm.Item name="species" label="Tipo do Animal" required>
                <Select
                  placeholder="Selecione um Animal"
                  data-testid="species-select"
                  options={PetSpecies.map((species) => ({
                    value: species,
                    label: species,
                  }))}
                />
              </AntForm.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8}>
              <AntForm.Item name="gender" label="Gênero" required>
                <Select
                  placeholder="Selecione um Gênero"
                  data-testid="gender-select"
                  options={[
                    { value: "MACHO", label: "Macho" },
                    { value: "FÊMEA", label: "Fêmea" },
                  ]}
                />
              </AntForm.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Row gutter={{ xs: 8, md: 16 }}>
                <Col xs={24} sm={24} md={12} lg={8}>
                  <AntForm.Item
                    name="location_address"
                    label="Endereço"
                    required
                  >
                    <Input placeholder="Endereço" required />
                  </AntForm.Item>
                </Col>
                <Col xs={12} sm={24} md={12} lg={8}>
                  <AntForm.Item name="neighborhood" label="Bairro" required>
                    <Select
                      placeholder="Selecione um Bairro"
                      data-testid="neighborhood-select"
                      options={[
                        ...neighborhood.map((item) => {
                          return {
                            value: item,
                            label: item,
                          };
                        }),
                      ]}
                    />
                  </AntForm.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} lg={6}>
              <AntForm.Item name={"image_url"} label="Fotos">
                {fileList && (
                  <Upload
                    fileList={fileList}
                    beforeUpload={() => false}
                    listType="picture-card"
                    accept="image/png, image/jpeg"
                    onPreview={handlePreview}
                    onChange={handleChange}
                    multiple
                  >
                    {fileList.length >= 8 ? null : uploadButton}
                  </Upload>
                )}
                {previewImage && (
                  <Image
                    wrapperStyle={{ display: "none" }}
                    preview={{
                      visible: previewOpen,
                      onVisibleChange: (visible) => setPreviewOpen(visible),
                      afterOpenChange: (visible) =>
                        !visible && setPreviewImage(""),
                    }}
                    src={previewImage}
                  />
                )}
              </AntForm.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <AntForm.Item
                initialValue={form.getFieldValue("description")}
                name={"description"}
                label="Descrição"
                rules={[{ required: true }]}
              >
                <Input.TextArea
                  className="!h-32 !resize-none"
                  placeholder="Adicione uma Descrição"
                />
              </AntForm.Item>
            </Col>
          </Row>
        </Card>

        <Flex justify="flex-end" align="center" gap={20}>
          <AntForm.Item noStyle>
            <Button status="danger" onClick={() => navigate(-1)}>
              Cancelar
            </Button>
          </AntForm.Item>
          <AntForm.Item noStyle>
            <Button
              htmlType="submit"
              status="success"
              data-testid="submit-form-ticket"
            >
              Enviar
            </Button>
          </AntForm.Item>
        </Flex>
      </Flex>
    </AntForm>
  );
};
