import { toast } from "react-toastify";
import { api } from ".";
import { IItem } from "../../domain/entities/Item";

export async function postItem(data: IItem) {
  try {
    await api.post(`/api/v1/item/`, data);

    toast.success("Licitação criado com sucesso");
  } catch (error) {
    toast.error("Erro ao realizar o cadastro da Licitação");
  }
}

export async function putItem(data: IItem) {
  try {
    const { id, ...body } = data;
    await api.put(`/api/v1/item/aditivate/${id}`, {
      licitation: {
        aditive_date: body.licitation?.aditive_date,
        new_validity: body.licitation?.new_validity,
        doc_aditive_url: body.licitation?.doc_aditive_url,
        reason_aditive: body.licitation?.reason_aditive,
      },
      items_services: body.items_services.map((service) => ({
        id: service.id,
        quantity: service.quantity,
      })),
    });

    toast.success("Licitação alterada com sucesso");
  } catch (error) {
    toast.error("Erro ao realizar a atualização da Licitação");
  }
}

export async function getItem(id: string): Promise<IItem | undefined> {
  try {
    return await api.get(`/api/v1/item/${id}`);
  } catch (error) {
    toast.error("Erro ao buscar a Licitação");
  }
}

export async function getAllItem(): Promise<IItem[] | undefined> {
  try {
    return await api.get(`/api/v1/item/`);
  } catch (error) {
    toast.error("Erro ao buscar as Licitações");
  }
}

export async function deleteItem(id: string) {
  try {
    await api.delete(`/items/${id}`);
    toast.success("Licitações excluído com sucesso");
  } catch (error) {
    toast.error("Erro ao deletar a Licitação");
  }
}
