import { toast } from "react-toastify";
import { api } from ".";
import { IItem } from "../../domain/entities/Item";

export async function getStock(): Promise<IItem[] | undefined> {
  try {
    return await api.get(`/api/v1/item/stock`);
  } catch (error) {
    toast.error("Erro ao buscar o Estoque");
  }
}

export async function putStock(id: string, allocation: number) {
  try {
    await api.put(`/api/v1/item/allocate/${id}`, { allocated: allocation });
    toast.success("Serviço empenhado com sucesso");
  } catch (error) {
    toast.error("Erro ao realizar a empenhar serviço");
  }
}
