import { IPet, PetRaceType, PetSpeciesType, TypeOfHome } from "./Pet";

export interface ICampaign {
  id: string;
  image_url: string;
  amount: number;
  campaign_locations: ICampaignLocations[];
  name: string;
  campaign_start_date: string;
  campaign_end_date: string;
  subscription_start_date: string;
  subscription_end_date: string;
  item_service_id: string;
  status: keyof typeof CampaignStatus;
  notice_url: string;
  canceled_reason: string;
}

export interface ICampaignLocations {
  campaign_id: string;
  date: string;
  id: string;
  neighborhood: string;
}

export interface ICampaignPet {
  id: string;
  campaign_locations_id: ICampaignLocations[];
  attendence_time: string;
  status: string;
  reason: string;
  pet_id: string;
  pet: IPet;
}

export enum CampaignStatus {
  SUBSCRIPTION_STARTED = "Inscricão iniciada",
  SUBSCRIPTION_ENDED = "Inscricão encerrada",
  CAMPAIGN_STARTED = "Campanha iniciada",
  CONCLUDED = "Concluido",
  CANCELED = "Cancelado",
  SCHEDULED = "Agendado",
}

export enum CampaignPetStatus {
  AWAITING = "Aguardando",
  REJECTED = "Rejeitado",
  IN_QUEUE = "Em fila",
  ATTENDED = "Compareceu",
  NOT_ATTENDED = "Não compareceu",
}

export type CampaignPetFilter = {
  neighborhood: string | "";
  pet_species: PetSpeciesType | "";
  pet_race: PetRaceType | "";
  pet_type_of_home: TypeOfHome | "";
  user_tutor_has_cadUnico: boolean | "";
  user_protector_is_protector: boolean | "";
};
