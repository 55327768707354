import { ITutor } from "./Tutor";

export interface IDenouncement {
  id: string;
  is_anonymous: boolean;
  image_url: string;
  location_address: string;
  location_cep: string;
  location_reference: string;
  neighborhood: string;
  protocol_number: string;
  type: string;
  gravity: string;
  species: string;
  observation: string;
  has_incident_report: boolean;
  incident_report_link: string;
  reporter_name: string;
  reporter_id_number: string;
  reporter_phone: string;
  status: string;
  date_ocurred: string;
  denouncement_item: IDenouncementItems[];
  tutor: ITutor;
  tutor_id: string;
}

export interface IDenouncementItems {
  id: string;
  denouncement_id: string;
  new_fact: string;
  denouncement_file: string;
  date: string;
  status: string;
  created_at: string;
  updated_at: string;
}

export enum DenouncementStatus {
  OPEN = "Aberto",
  ON_GOING = "Em andamento",
  EXTERNAL_PROCESS = "Processo externo",
  CLOSED = "Fechado",
}

export enum DenouncementGravity {
  LEVE = "Leve",
  GRAVE = "Grave",
  GRAVISSIMA = "Gravissimo",
  DESCONHECIDO = "Desconhecido",
}
