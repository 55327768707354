import { api } from ".";

export async function verifyAccount(token: string): Promise<void> {
  return await api.get(`/api/v1/auth/verify-tutor/${token}`);
}

export async function resetPassword(
  token: string,
  new_password: string
): Promise<void> {
  return await api.post(`/api/v1/auth/reset-password/${token}`, {
    new_password,
  });
}
