import { useCallback, useState } from "react";
import { getStock, putStock } from "../../data/service/stock";
import { IItem } from "../../domain/entities/Item";

export function useStock() {
  const [loading, setLoading] = useState<boolean>(false);
  const [stockList, setAllocationList] = useState<IItem[]>([]);

  const handleGetStock = useCallback(async () => {
    setLoading(true);
    const response = await getStock();
    if (response) {
      setAllocationList(response);
    }
    setLoading(false);
  }, []);

  const handlePutAllocation = useCallback(
    async (id: string, allocation: number) => {
      setLoading(true);
      await putStock(id, allocation);
      setLoading(false);
    },
    []
  );

  return {
    loading,
    stockList,
    actions: {
      handleGetStock,
      handlePutAllocation,
    },
  };
}
