import { toast } from "react-toastify";
import { api } from ".";
import { IServiceProvider } from "../../domain/entities/ServiceProvider";

export async function postServiceProvider(data: IServiceProvider) {
  try {
    await api.post(`/api/v1/user-service-providers/`, data);

    toast.success("Prestador de Serviços criado com sucesso");
  } catch (error) {
    toast.error("Erro ao realizar o cadastro do Prestador de Serviço");
  }
}

export async function patchServiceProvider(data: IServiceProvider) {
  try {
    const { id, ...body } = data;
    await api.patch(`/api/v1/user-service-providers/${id}`, body);

    toast.success("Prestador de Serviços alterado com sucesso");
  } catch (error) {
    toast.error("Erro ao realizar o cadastro do Prestador de Serviço");
  }
}

export async function getServiceProvider(
  id: string
): Promise<IServiceProvider | undefined> {
  try {
    return await api.get(`/api/v1/user-service-providers/${id}`);
  } catch (error) {
    toast.error("Erro ao buscar o Prestador de Serviço");
  }
}

export async function getAllServiceProvider(): Promise<
  IServiceProvider[] | undefined
> {
  try {
    return await api.get(`/api/v1/user-service-providers/`);
  } catch (error) {
    toast.error("Erro ao buscar o Prestador de Serviço");
  }
}

export async function deleteServiceProvider(id: string) {
  try {
    await api.delete(`/api/v1/user-service-providers/${id}`);
    toast.success("Prestador de Serviços excluído com sucesso");
  } catch (error) {
    toast.error("Erro ao buscar o Prestador de Serviço");
  }
}
