import React, { useEffect, useMemo, useReducer } from "react";
import {
  Button,
  Typography,
  Flex,
  Space,
  Card,
  Input,
  Table,
  Row,
  Select,
  DatePicker,
  TableProps,
  Tag,
} from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../Router";
import { useDenouncement } from "../../../hooks/useDenouncement";
import {
  DenouncementGravity,
  DenouncementStatus,
  IDenouncement,
} from "../../../../domain/entities/Denouncement";
import { useConfig } from "../../../hooks/useConfig";
import isBetween from "dayjs/plugin/isBetween";
import dayjs from "dayjs";

const { Text } = Typography;
const { RangePicker } = DatePicker;
dayjs.extend(isBetween);

export const List: React.FC = () => {
  const navigate = useNavigate();

  const {
    denouncementList,
    loading,
    actions: { handleGetAllDenouncements },
  } = useDenouncement();

  const { neighborhood } = useConfig();

  const filterReducer = (filter: any, info: any) => ({
    ...filter,
    ...info,
  });

  const gravityTag = useMemo(() => {
    return {
      DESCONHECIDO: "blue",
      GRAVE: "orange",
      GRAVISSIMA: "magenta",
      LEVE: "green",
    };
  }, []);

  const [filter, changeFilter] = useReducer(filterReducer, {
    searchIdentifier: "",
    neighborhood: "",
    gravity: "",
    status: "",
    dates: { start: "", end: "" },
  });

  const columns: TableProps<IDenouncement>["columns"] = useMemo(
    () => [
      {
        title: "Identificador",
        dataIndex: "protocol_number",
        key: "protocol_number",
        ellipsis: {
          showTitle: false,
        },
        render: (_: any, record: IDenouncement) => (
          <Text>{record.protocol_number}</Text>
        ),
      },
      {
        title: "Bairro",
        dataIndex: "neighborhood",
        key: "neighborhood",
        responsive: ["lg"],
        render: (_: any, record: IDenouncement) => (
          <Text>{record.neighborhood}</Text>
        ),
      },
      {
        title: "Gravidade",
        dataIndex: "gravity",
        key: "gravity",
        responsive: ["md"],
        render: (_: any, record: IDenouncement) => (
          <Tag
            color={
              gravityTag[record.gravity as keyof typeof DenouncementGravity]
            }
          >
            {
              DenouncementGravity[
                record.gravity as keyof typeof DenouncementGravity
              ]
            }
          </Tag>
        ),
      },
      {
        title: "Denúncia",
        dataIndex: "report_type",
        key: "report_type",
        ellipsis: {
          showTitle: false,
        },
        responsive: ["xl"],
        render: (_: any, record: IDenouncement) => <Text>{record.type}</Text>,
      },

      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (_: any, record: IDenouncement) => (
          <Text>
            {
              DenouncementStatus[
                record.status as keyof typeof DenouncementStatus
              ]
            }
          </Text>
        ),
      },
      {
        title: "Data",
        dataIndex: "data_ocurred",
        key: "data_ocurred",
        responsive: ["xl"],
        render: (_: any, record: IDenouncement) => (
          <Text>{dayjs(record.date_ocurred).format("DD/MM/YYYY")}</Text>
        ),
      },
      {
        title: "Ações",
        dataIndex: "actions",
        key: "actions",
        width: 120,
        render: (_: any, record: IDenouncement) => {
          return (
            <Space size="small">
              <Button
                className="p-2 flex items-center justify-center"
                type="text"
                data-testid="view-denouncement"
                onClick={() =>
                  navigate(
                    ROUTES.DASHBOARD.DENOUNCEMENT.INFO.replace(":id", record.id)
                  )
                }
              >
                <EyeOutlined size={18} />
              </Button>
            </Space>
          );
        },
      },
    ],
    [navigate, gravityTag]
  );

  const filterDenouncements = useMemo(() => {
    if (!denouncementList) {
      return [];
    }
    return denouncementList
      ?.filter((denouncementFilter) =>
        denouncementFilter.protocol_number
          .toLowerCase()
          .includes(filter.searchIdentifier.toLowerCase())
      )
      .filter((denouncementFilter) =>
        filter.neighborhood !== ""
          ? denouncementFilter.neighborhood === filter.neighborhood
          : true
      )
      .filter((denouncementFilter) =>
        filter.gravity !== ""
          ? denouncementFilter.gravity === filter.gravity
          : true
      )
      .filter((denouncementFilter) =>
        filter.status !== ""
          ? denouncementFilter.status === filter.status
          : true
      )
      .filter((denouncementFilter) =>
        filter.dates.start !== "" && filter.dates.end !== ""
          ? dayjs(denouncementFilter.date_ocurred).isBetween(
              filter.dates.start,
              filter.dates.end
            ) ||
            dayjs(denouncementFilter.date_ocurred).isSame(filter.dates.start) ||
            dayjs(denouncementFilter.date_ocurred).isSame(filter.dates.end)
          : true
      );
  }, [denouncementList, filter]);

  useEffect(() => {
    handleGetAllDenouncements();
  }, [handleGetAllDenouncements]);

  return (
    <Card className="w-full">
      <Flex vertical gap="middle" wrap="wrap">
        <Row>
          <Flex gap={10} align="centers" wrap="wrap">
            <Flex vertical justify="flex-start" align="flex-start">
              <Text>Identificador</Text>
              <Input
                placeholder="123412344 "
                value={filter.searchIdentifier}
                onChange={(e) =>
                  changeFilter({ searchIdentifier: e.target.value })
                }
              />
            </Flex>
            <Flex vertical justify="flex-start" align="flex-start" wrap="wrap">
              <Text>Bairro</Text>
              <Space wrap>
                <Select
                  showSearch
                  defaultValue={filter.neighborhood}
                  className="w-60"
                  onChange={(value) => changeFilter({ neighborhood: value })}
                  options={[
                    { value: "", label: "Todos" },
                    ...neighborhood.map((item) => {
                      return {
                        value: item,
                        label: item,
                      };
                    }),
                  ]}
                />
              </Space>
            </Flex>
            <Flex vertical justify="flex-start" align="flex-start">
              <Text>Gravidade</Text>
              <Space wrap>
                <Select
                  showSearch
                  defaultValue={filter.gravity}
                  className="w-60"
                  onChange={(value) => changeFilter({ gravity: value })}
                  options={[
                    { value: "", label: "Todos" },
                    { value: "LEVE", label: "Leve" },
                    { value: "GRAVE", label: "Grave" },
                    { value: "GRAVISSIMA", label: "Gravissimo" },
                    { value: "DESCONHECIDO", label: "Desconhecido" },
                  ]}
                />
              </Space>
            </Flex>
            <Flex vertical justify="flex-start" align="flex-start">
              <Text>Status</Text>
              <Space wrap>
                <Select
                  defaultValue={filter.status}
                  className="w-60"
                  onChange={(value) => changeFilter({ status: value })}
                  options={[
                    { value: "", label: "Todos" },
                    { value: "OPEN", label: "Aberto" },
                    { value: "ON_GOING", label: "Em Andamento" },
                    { value: "EXTERNAL_PROCESS", label: "Processo Externo" },
                    { value: "CLOSED", label: "Fechado" },
                  ]}
                />
              </Space>
            </Flex>
            <Flex vertical justify="flex-start" align="flex-start" wrap="wrap">
              <Text>Data</Text>
              <Space>
                <RangePicker
                  title="Data"
                  format={"DD/MM/YYYY"}
                  placeholder={["Data Inicial", "Data Final"]}
                  onChange={(value) => {
                    changeFilter({
                      dates: value
                        ? { start: value[0], end: value[1] }
                        : { start: "", end: "" },
                    });
                  }}
                />
              </Space>
            </Flex>
          </Flex>
        </Row>

        <Table
          className="w-full border rounded"
          columns={columns}
          dataSource={filterDenouncements?.sort((a, b) => {
            const severityOrder: any = {
              Desconhecido: 4,
              Gravissimo: 3,
              Grave: 2,
              Leve: 1,
            };
            return severityOrder[b.gravity] - severityOrder[a.gravity];
          })}
          pagination={{ pageSize: 8 }}
          loading={loading}
          rowKey={(row) => row.id}
        />
      </Flex>
    </Card>
  );
};
