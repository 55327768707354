import React from "react";
import { Router } from "./Router";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { UserProvider } from "../domain/context/user/UserContext";

export const Main: React.FC = () => {
  return (
    <>
      <UserProvider>
        <ToastContainer />
        <Router />
      </UserProvider>
    </>
  );
};
