import { useCallback, useState } from "react";
import { IVeterinarian } from "../../domain/entities/Veterinarian";
import { getAllVeterinarians } from "../../data/service/veterinarian";

export function useVeterinarian() {
  const [loading, setLoading] = useState(false);
  const [veterinarianList, setVeterinarianList] = useState<IVeterinarian[]>([]);

  const handleGetAllVeterinarians = useCallback(async () => {
    setLoading(true);
    setVeterinarianList(await getAllVeterinarians());
  }, []);

  return { loading, veterinarianList, actions: { handleGetAllVeterinarians } };
}
