import { toast } from "react-toastify";
import { api } from ".";
import { IConfig } from "../../domain/entities/Config";
import { IService } from "../../domain/entities/Service";

export async function getConfig(): Promise<IConfig | undefined> {
  try {
    return await api.get("/api/v1/config/");
  } catch (error) {
    toast.error("Erro ao buscar as Configurações");
  }
}

export async function postService(data: IService): Promise<void> {
  return await api.post("/api/v1/config/create/service", data);
}
