import { Col, Flex, Modal, Row } from "antd";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../../../domain/context/user/UserContext";
import { Button } from "../../../../components/Button";
import { useProtector } from "../../../../hooks/useProtector";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../../../../Router";
import { TutorCard } from "../../../../components/Cards/TutorCard";

export const Info: React.FC = () => {
  const userContext = useContext(UserContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    activeProtector,
    actions: {
      handleGetActiveProtectors,
      handleDelete,
      handleGetAllActiveProtectors,
    },
  } = useProtector();

  const openModal = (id: string) => {
    setSelectedId(id);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleDeleteProtector = async (id: string) => {
    handleDelete(id as string);
    closeModal();
    navigate(`${ROUTES.DASHBOARD.PROTECTOR.ACTIVE.ROOT}`);
    handleGetAllActiveProtectors();
  };

  useEffect(() => {
    handleGetActiveProtectors(id as string);
  }, [id, handleGetActiveProtectors]);

  return (
    <>
      <Flex vertical gap="small" align="flex-end" className="w-full">
        <Row className="w-full">
          <Col span={24}>
            {activeProtector && <TutorCard {...activeProtector} />}
          </Col>
        </Row>
        <Row className="gap-3 ml-auto">
          {userContext.user?.scopes?.includes("protector:delete") && (
            <Button
              data-testid="open-modal-button"
              type="default"
              status="success"
              onClick={() => openModal(id as string)}
            >
              Desnvincular
            </Button>
          )}
          <Button type="default" status="info" onClick={() => navigate(-1)}>
            Voltar
          </Button>
        </Row>
      </Flex>
      <Modal
        title="Deseja se desvincular como Protetor?"
        open={isModalOpen}
        onCancel={closeModal}
        footer={[
          <Button
            status="success"
            key="submit"
            data-testid="success-modal-button"
            onClick={() => handleDeleteProtector(selectedId)}
            loading={false}
          >
            Sim
          </Button>,
          <Button
            data-testid="unsuccess-modal-button"
            key="back"
            status="danger"
            onClick={closeModal}
          >
            Não
          </Button>,
        ]}
      ></Modal>
    </>
  );
};
