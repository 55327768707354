import { Navigate, Outlet, useLocation } from "react-router-dom";
import { UserContext } from "../../../../domain/context/user/UserContext";
import { useContext } from "react";
import { ROUTES } from "../../../Router";

export * from "./List";
export * from "./Info";
export * from "./Form";

export * as ServiceProvider from "./ServiceProvider/List";

export const RequirementRoot: React.FC = () => {
  const userContext = useContext(UserContext);

  const location = useLocation();

  if (
    !userContext.user?.scopes?.some((scope) => scope.startsWith("requirement:"))
  ) {
    return <Navigate to={ROUTES.DASHBOARD.ROOT} />;
  }

  if (
    userContext.user.scopes.includes("requirement:requirement_services:read") &&
    location.pathname !== ROUTES.DASHBOARD.REQUIREMENT.SERVICE_PROVIDER.ROOT
  ) {
    return <Navigate to={ROUTES.DASHBOARD.REQUIREMENT.SERVICE_PROVIDER.ROOT} />;
  }

  return <Outlet />;
};
