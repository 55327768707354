import { api } from ".";
import { IDashboard } from "../../domain/entities/Dashboard";

export async function getDashboard(
  neighborhood?: string,
  months: number = 5
): Promise<IDashboard | undefined> {
  let query = `?months=${months}`;

  if (neighborhood) {
    query += `&neighborhood=${neighborhood}`;
  }

  return await api.get(`/api/v1/dashboard/${query}`);
}
