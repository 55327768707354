export const flattenObj = (object: { [key: string]: any }) => {
  let result: { [key: string]: any } = {};

  if (object) {
    Object.entries(object).forEach(([parentKey, parentValue]) => {
      if (typeof parentValue === "object" && !Array.isArray(parentValue)) {
        const temp = flattenObj(parentValue);

        Object.entries(temp).forEach(([childKey, childValue]) => {
          result[`${parentKey}.${childKey}`] = childValue;
        });
      } else {
        result[parentKey] = parentValue;
      }
    });
  }
  return result;
};
