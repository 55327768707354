import { toast } from "react-toastify";
import { IUser } from "../../domain/entities/User";
import { api } from ".";
import { Cookies } from "react-cookie";

export async function signIn(user: {
  email: string;
  password: string;
}): Promise<IUser | undefined> {
  try {
    return await api.post("/api/v1/auth/login", user);
  } catch (error) {
    toast.error("Erro ao realizar o login");
  }
}

export async function revokeAccessToken(): Promise<void> {
  try {
    await api.delete("/api/v1/auth/revoke_access");
  } catch (error) {
    toast.error("Erro ao revogar o access token");
  }
}

export async function revokeRefreshToken(): Promise<void> {
  const cookies = new Cookies();

  try {
    await api.delete("/api/v1/auth/refresh/revoke_refresh", {
      headers: {
        "X-CSRF-TOKEN-REFRESH": cookies.get("csrf_refresh_token"),
      },
    });
  } catch (error) {
    toast.error("Erro ao revogar o refresh token");
  }
}

export async function refreshToken(): Promise<void> {
  const cookies = new Cookies();
  try {
    await api.get("/api/v1/auth/refresh", {
      headers: {
        "X-CSRF-TOKEN-REFRESH": cookies.get("csrf_refresh_token"),
      },
    });
  } catch (error) {}
}
